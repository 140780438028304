<template>
	<!-- eslint-disable  -->
	<div id="recaptcha-app">
		<div class="box">
			<div class="recaptcha-cover" v-if="!show">
				<h3 class="text-lg font-bold mb-6 text-dark">Подтвердите, что вы не робот</h3>
				<div class="recaptcha flex items-center px-3 py-2 pt-3 rounded-sm">
					<div class="w-3/4 text-sm">
						<div class="flex items-center cursor-pointer -mx-2">
							<div class="px-2">
								<input type="checkbox" v-model="input" @click="start" class="checkbox w-8 h-8 border-2 border-gray-400 rounded-sm hover:border-gray-600 cursor-pointer" />
							</div>
							<div class="px-2 punchline">
								<p>Я не робот</p>
							</div>
						</div>
					</div>
					<div class="recaptcha-info w-1/4 text-center text-gray-700">
						<div>
							<img class="w-2/4 mx-auto mb-1" src="https://www.gstatic.com/recaptcha/api2/logo_48.png" alt="Recaptch logo" />
							<p>reCAPTCHA</p>
						</div>
						<div><span>Privacy</span> - <span>Terms</span></div>
					</div>
				</div>
			</div>

			<div class="recaptcha-cover" v-if="show">
				<div>
					<h3 class="text-lg font-bold mb-6 text-dark">Найдите медицинских работников</h3>
					<div class="mondrian mb-6">
						<div
							class="mondrian__block"
							v-for="(block, ind) in blocks"
							:key="ind"
							:index="block.colorIndex"
							:data-row-span="block.rowSpan"
							:data-col-span="block.colSpan"
							:id="block.id"
							@click="clickpicture"
						>
							<p>{{ blocksText[block.colorIndex - 1] }}</p>
						</div>
					</div>
					<div class="error text-red-500" v-show="showError">Не верно, попробуйте еще раз!</div>
					<div style="margin-top: 20px; font-size: 11pt; font-weight: bold; max-width: 600px">
						Нажимая кнопку 'Готово', Вы принимаете
						<a
							href="https://minzdrav.gov.ru/ministry/web-site/confident"
							style="margin-top: 0px; font-size: 11pt; font-weight: bold; max-width: 600px; padding: display: block; color: black; text-decoration: underline"
							>политику конфиденциальности Министерства здравоохранения России</a
						>
					</div>
					<button class="btn-cap text-center bg-dopBlue py-5 text-white text-sm rounded-xl font-bold" @click.prevent="regenerate">Готово</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { mutations } from '@/store/store';
import tickPng from '@/assets/capcha/tick.png';

export default {
	data() {
		return {
			input: false,
			show: false,
			showError: false,
			blocks: [],
			blocksColInd: [], // массив индекса картинок
			blocksText: [
				'Мужчина в костюме портье',
				'Мужчина с бородой, в очках и в медицинском халате',
				'Мужчина с усами и в фуражке в костюме портье',
				'Женщина в медицинском халате',
				'Женщина в поварском колпаке',
				'Женщина в очках и в медицинском халате',
				'Женщина в свитере и в очках',
				'Мужчина в очках и в медицинском халате',
				'Мужчина в свитере, в солнечных очках и с бородой',
				'Женщина в медицинской маске и в медицинском халате',
				'Мужчина в очках, галстуке и с бородой',
				'Мужчина с усами и бородой, в очках и в медицинском халате',
				'Женщина в очках и в пиджаке',
				'Женщина в медицинской маске и в медицинских халате и шапке',
				'Женщина в очках и с микрофоном',
				'Женщина в медицинском халате и с лобным рефлектором'
			],
			blocksCountIndNo: [], // массив четных картинок на странице
			blocksCountIndYes: [], // массив нечетных картинок на странице
			idpict: []
		};
	},
	methods: {
		...mutations,
		start() {
			this.input = false;
			this.show = !this.show;
		},
		generateBlocks() {
			this.blocks = [];
			let numbers = [];
			for (let i = 0; this.blocks.length < 6; i++) {
				let randomNumber = Math.floor(Math.random() * 16 + 1);
				if (!numbers.includes(randomNumber)) {
					numbers.push(randomNumber);
					this.blocks.push({
						colSpan: i,
						rowSpan: i,
						colorIndex: randomNumber,
						id: Math.random().toString(16).slice(2)
					});
				}
			}

			let menuItem = document.querySelectorAll('.sale-2');

			for (let i = 0; i < menuItem.length; i++) {
				menuItem[i].remove();
			}
		},
		regenerate() {
			this.idpict.sort(function (a, b) {
				return a - b;
			});
			if (this.idpict.join('') === this.blocksCountIndYes.join('')) {
				this.input = true;
				this.show = false;
				this.setCapcha(true);
			} else {
				this.idpict = [];
				this.func();
				this.showError = true;
			}
		},
		clickpicture(id, index, tag, child) {
			tag = id.target.tagName;
			let idd = id.target.parentNode;
			child = idd.children;
			index = idd.attributes.index.value;
			id = idd.id;
			let elem = document.getElementById(id);
			let sp = document.createElement('img');

			if (tag === 'P' && child.length === 1) {
				sp.classList.add('sale-2');
				sp.id = Math.random().toString(16).slice(2);
				sp.setAttribute('src', tickPng);
				sp.setAttribute('index', elem.attributes.index.value);
				elem.appendChild(sp);
				this.idpict.push(`${index}`);
			} else if (tag === 'IMG') {
				let select = elem.getElementsByTagName('IMG');
				elem.removeChild(select[0]);
				this.idpict.splice(this.idpict.indexOf(index), 1);
			}
		},
		func() {
			this.blocks = [];
			this.blocksColInd = [];
			this.blocksCountIndNo = [];
			this.blocksCountIndYes = [];
			this.generateBlocks();
			for (let i = 0; i < this.blocks.length; i++) {
				this.blocksColInd.push(this.blocks[i].colorIndex);
			}
			for (let g = 0; g < this.blocksColInd.length; g++) {
				this.blocksColInd[g] % 2 ? this.blocksCountIndNo.push(this.blocksColInd[g]) : this.blocksCountIndYes.push(`${this.blocksColInd[g]}`);
			}
			this.blocksCountIndYes.sort(function (a, b) {
				return a - b;
			});
		}
	},
	mounted() {
		this.generateBlocks();
		for (let i = 0; i < this.blocks.length; i++) {
			this.blocksColInd.push(this.blocks[i].colorIndex);
		}
		for (let g = 0; g < this.blocksColInd.length; g++) {
			this.blocksColInd[g] % 2 ? this.blocksCountIndNo.push(this.blocksColInd[g]) : this.blocksCountIndYes.push(`${this.blocksColInd[g]}`);
		}
		this.blocksCountIndYes.sort(function (a, b) {
			return a - b;
		});
	}
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Lato:300,900');
* {
	box-sizing: border-box;
	animation: fadeIn 0.5s;
}
.mondrian p {
	text-indent: -9999px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background: none !important;
}
.special .mondrian p {
	text-indent: 0px;
	padding: 10px;
	background: white !important;
}
.btn-cap {
	width: 216px;
	margin-top: 40px;
}
.sale-2 {
	display: block;
	filter: blur(1px);
	position: absolute;
	right: 2px;
	width: 121px;
	top: -9px;
	padding: 10px;
	z-index: 10;
}
.orangered {
	color: #ff4500;
}
.mondrian {
	background-color: #070908;
	border: 10px solid #070908;
	border-radius: 20px;
	box-shadow: 5px 10px 10px #aaa;
	display: grid;
	display: -ms-grid;
	-ms-grid-columns: 125px;
	grid-flow: dense;
	-ms-grid-rows: 125px;
	padding-left: 2px;
	padding-top: 3px;
	grid-template-columns: repeat(auto-fit, 125px);
	grid-template-rows: repeat(auto-fit, 125px);
	height: 275px;
	overflow: hidden;
	width: 400px;
}
.mondrian__block:hover {
	border: 2px solid #979797;
}
.mondrian__block:nth-child(1) {
	animation-delay: 0.15s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.mondrian__block:nth-child(2) {
	animation-delay: 0.3s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.mondrian__block:nth-child(3) {
	animation-delay: 0.45s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.special .btn-cap {
	border: 1px solid #000;
}
.mondrian__block:nth-child(4) {
	animation-delay: 0.6s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.mondrian__block:nth-child(5) {
	animation-delay: 0.75s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.mondrian__block:nth-child(6) {
	animation-delay: 0.9s;
	background-color: $color;
	transform: scale(0);
	animation-fill-mode: forwards;
}
.mondrian__block:nth-child(1) {
	-ms-grid-column: 1;
	-ms-grid-row: 1;
}
.mondrian__block:nth-child(2) {
	-ms-grid-column: 2;
	-ms-grid-row: 1;
}
.mondrian__block:nth-child(3) {
	-ms-grid-column: 3;
	-ms-grid-row: 1;
}
.mondrian__block:nth-child(4) {
	-ms-grid-column: 1;
	-ms-grid-row: 2;
}
.mondrian__block:nth-child(5) {
	-ms-grid-column: 2;
	-ms-grid-row: 2;
}
.mondrian__block:nth-child(6) {
	-ms-grid-column: 3;
	-ms-grid-row: 2;
}
.mondrian__block[data-row-span='1'] {
	grid-row: span 1;
}
.mondrian__block[data-col-span='1'] {
	grid-column: span 1;
}
.box {
	@apply py-6 px-4 shadow-default rounded-xl sm:px-12 sm:py-6 bg-white sm:mb-6;
}
.mondrian__block[index='1'] {
	background-image: url('~@/assets/capcha/nnodoc1.png') !important;
}
.mondrian__block[index='2'] {
	background-image: url('~@/assets/capcha/ddoc1.png') !important;
}
.mondrian__block[index='3'] {
	background-image: url('~@/assets/capcha/nnodoc2.png') !important;
}
.mondrian__block[index='4'] {
	background-image: url('~@/assets/capcha/ddoc2.png') !important;
}
.mondrian__block[index='5'] {
	background-image: url('~@/assets/capcha/nnodoc3.png') !important;
}
.mondrian__block[index='6'] {
	background-image: url('~@/assets/capcha/ddoc3.png') !important;
}
.mondrian__block[index='7'] {
	background-image: url('~@/assets/capcha/nnodoc4.png') !important;
}
.mondrian__block[index='8'] {
	background-image: url('~@/assets/capcha/ddoc4.png') !important;
}
.mondrian__block[index='9'] {
	background-image: url('~@/assets/capcha/nnodoc5.png') !important;
}
.mondrian__block[index='10'] {
	background-image: url('~@/assets/capcha/ddoc5.png') !important;
}
.mondrian__block[index='11'] {
	background-image: url('~@/assets/capcha/nnodoc6.png') !important;
}
.mondrian__block[index='12'] {
	background-image: url('~@/assets/capcha/ddoc6.png') !important;
}
.mondrian__block[index='13'] {
	background-image: url('~@/assets/capcha/nnodoc7.png') !important;
}
.mondrian__block[index='14'] {
	background-image: url('~@/assets/capcha/ddoc7.png') !important;
}
.mondrian__block[index='15'] {
	background-image: url('~@/assets/capcha/nnodoc8.png') !important;
}
.mondrian__block[index='16'] {
	background-image: url('~@/assets/capcha/ddoc8.png') !important;
}
.generate-button {
	cursor: pointer;
	padding: 4px 12px;
	border-radius: 4px;
	font-family: 'Lato', sans-serif;
	margin-top: 30px;
	background-color: #fff;
}
.generate-button:hover {
	background-color: #e6e6e6;
}
.generate-button:active {
	background-color: #ccc;
}
.recaptcha {
	font-family: Roboto, helvetica, arial, sans-serif;
	width: 305px;
	background-color: #f9f9f9;
	border: 1px solid #d3d3d3;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}
.special .recaptcha {
	width: auto;
}
.nomargin .recaptcha {
	margin: 0;
}
.recaptcha-info {
	font-size: 0.5rem;
}
.recaptcha-info p {
	font-size: 0.7rem;
}
.recaptcha-test {
	font-family: Roboto, helvetica, arial, sans-serif;
	width: 440px;
}
.recaptcha-test::after {
	content: '';
	display: block;
	position: absolute;
	top: 19px;
	left: -26px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 18px 30px 18px 0;
	border-color: transparent #fff transparent transparent;
}
.recaptcha-test p {
	font-size: 0.9rem;
}
.recaptcha-test p.sub {
	font-size: 1.4rem;
}
.recaptcha-test .recaptcha-info p {
	font-size: 0.7rem;
}
.recaptcha-cover {
	position: relative;
}
.recaptcha + .recaptcha-test {
	position: absolute;
	top: 0;
	left: 170px;
	z-index: 9999;
}
.checkbox {
	transition: border-color 0.1s;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

body .mondrian__block {
	animation: scaleIn 0.25s ease 0s;
	background-color: url('~@/assets/capcha/nnodoc1.png');
	background-size: 100% 100% !important;
	cursor: pointer;
	width: 125px;
	height: 125px;
}
@-moz-keyframes scaleIn {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
@-webkit-keyframes scaleIn {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
@-o-keyframes scaleIn {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
@keyframes scaleIn {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
@-moz-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (max-width: 600px) {
	.box {
		padding: 0;
		box-shadow: none;
	}

	.mondrian {
		grid-template-columns: repeat(auto-fit, 33%);
		grid-template-rows: repeat(auto-fit, 50%);
		overflow: hidden;
		width: 100%;
	}

	body .mondrian__block {
		width: auto;
		height: auto;
	}

	.btn-cap {
		@apply fixed bottom-2 left-2 right-2 w-auto;
	}
}
</style>
