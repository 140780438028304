<template>
	<div class="hidden relative sm:flex flex-col flex-grow items-center w-36 max-w-1/12 min-h-screen m-0 p-9 bg-white filter shadow-default z-30 w-auto">
		<!-- <img class="absolute top-10 -right-14 p-1 cursor-pointer" @click.prevent="menuOpened = !menuOpened" src="@/assets/img/burger.svg" /> -->
		<img class="w-16" src="@/assets/img/logo.png" />
		<p class="flex w-72 mt-8 text-xs text-center uppercase sub-text">Портал независимой оценки качества условий оказания услуг медицинскими организациями</p>
		<ul class="flex flex-col w-full mb-auto pb-20 pt-12 border-t border-gray-100 mt-11" v-if="$route.params.type !== 'anketa'">
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.ambulator"
				:class="{ active: !$route.params.type || $route.params.type == 'ambulator' || $route.params.type == '121' }"
				@click="link('ambulator')"
			>
				<p class="flex p-2">Амбулаторные условия</p>
			</li>
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.stacionar"
				:class="{ active: $route.params.type == 'stacionar' || $route.params.type == '125' }"
				@click="link('stacionar')"
			>
				<p class="flex p-2">Стационарные условия</p>
			</li>
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.healthResort"
				:class="{ active: $route.params.type == 'healthResort' || $route.params.type == '114' }"
				@click="link('healthResort')"
			>
				<p class="flex p-2">Санаторно-курортные организации</p>
			</li>
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.psychiatric"
				:class="{ active: $route.params.type == 'psychiatric' || $route.params.type == '113' }"
				@click="link('psychiatric')"
			>
				<p class="flex p-2">Психиатрические больницы</p>
			</li>
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.donor"
				:class="{ active: $route.params.type == 'donor' || $route.params.type == '112' }"
				@click="link('donor')"
			>
				<p class="flex p-2">Донорство крови</p>
			</li>
			<li
				class="menu-li"
				v-if="$route.name == 'QuestionnaireRegion' || $route.name == 'QuestionnaireRegion2' || dataMO.skoraya"
				:class="{ active: $route.params.type == 'skoraya' || $route.params.type == '116' }"
				@click="link('skoraya')"
			>
				<p class="flex p-2">Скорая помощь</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { getters, actions, mutations } from '@/store/store';

export default {
	name: 'Menu',
	data() {
		return {
			menuOpened: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		...mutations,
		link(route) {
			let anketType = this.$route.params.type;
			if (route !== anketType) {
				let routeName = this.$route.name;
				if (routeName === 'QuestionnaireRegion2') routeName = 'QuestionnaireRegion';
				if (routeName === 'Questionnaire2') routeName = 'Questionnaire';
				// console.log('this.$route.name=', this.$route.params.idorg);
				let routeId = this.$route.params.id;
				if (!routeId) routeId = 0;
				this.$router.push({ name: routeName, params: { type: route, id: routeId, idorg: this.$route.params.idorg } });
			}
			if (!anketType) anketType = 'ambulator';
			this.changeType(route);
			this.getAnketaById();
			if (this.$route.name === 'QuestionnaireRegion' || this.$route.name === 'QuestionnaireRegion2') {
				let query = {
					text: '',
					id: this.$route.params.id
				};
				if (query.id.length < 10) {
					let gotRegion = this.getRegionById(query.id);
					gotRegion.then((res) => {
						query.id = res.data.id;
						this.getMOList(query);
					});
				} else {
					this.getMOList(query);
				}
				this.setSearch('');

				// если не Донорство и не Скорая
				if (route !== 'donor' && route !== 'skoraya') {
					this.setMO(false);
				} else {
					this.setMO(true);
				}
			}
			this.$emit('callMethodInParent');
		}
	}
};
</script>

<style scoped>
.active::before {
	@apply block w-3 h-14 bg-blue-500 absolute rounded-xl;
	content: '';
	left: -6px;
}

.menu-li {
	@apply flex flex-row h-14 mr-auto mb-6 ml-5 items-center text-sm cursor-pointer;
}

.menu-li.active p {
	@apply text-blue-500;
}

.special .sub-text {
	width: 100%;
}

.special .filter {
	max-width: 40%;
}
</style>
