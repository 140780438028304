import Vue from 'vue';
import _ from 'lodash';

export const state = Vue.observable({
	MobileMenu: false,
	anketTypeName: 'ambulator',
	additionalQuestions: [],
	search: '',
	capchaState: false,
	Regions: [],
	infoAnket: {
		created: '',
		medicalOrgName: '',
		numberBlock: '',
		titleBlock: '',
		completedQuestions: 0,
		totalQuestions: 0,
		result: 0,
		medicalId: '',
		anketaId: '',
		userFullName: ''
	},
	Region: null,
	surveyCurrent: [],
	surveyFull: [],
	moItems: [],
	anketCode: null,
	anketType: '',
	title: '',
	disabledState: false,
	questionNumber: 0,
	mo: {},
	moError: null
});

export const getters = {
	disabled: () => state.disabledState,
	capcha: () => state.capchaState,
	anketRoutType: () => state.anketType,
	getSearch: () => state.search,
	getTitle: () => state.infoAnket.titleBlock,
	getMoArr: () => state.moItems,
	menu: () => state.MobileMenu,
	questionNumber: () => state.questionNumber,
	getAnketCode: () => state.anketCode,
	dataMO: () => state.mo,
	moError: () => state.moError,
	survey: () => state.surveyCurrent,
	regionsArr: () => state.Regions,
	currentRegion: () => state.Region
};

export const mutations = {
	setRegions(arr) {
		state.Regions = arr;
	},
	setCapcha(bool) {
		state.capchaState = bool;
	},
	setSearch(text) {
		state.search = text;
	},
	nextAnswer(index) {
		state.questionNumber = index;
	},
	setAnket(data) {
		if (!state.mo) {
			state.search = '';
			state.disabledState = false;
		}
		state.infoAnket = {
			titleBlock: data.name,
			anketaId: data.anketaId
		};
		state.title = data.name;

		state.surveyFull = data.questionBlocks[0].questions;
		state.surveyFull.sort((a, b) => a.blockNumber - b.blockNumber);

		state.additionalQuestions = [];

		state.surveyFull.forEach((question) => {
			question.answers.forEach((answer) => {
				answer.dependencyQuestions.forEach((x) => {
					state.additionalQuestions.push(x.dependencyQuestionId);
				});
			});
		});

		state.additionalQuestions = _.uniq(state.additionalQuestions);

		this.resetSurvey();
	},
	setMO(flag, data) {
		if (!flag) {
			state.disabledState = false;
			state.mo = {};

			return;
		}

		state.disabledState = true;
		if (data) state.mo = data;
	},
	setMoError(message) {
		state.moError = message;
	},
	setRegion(data) {
		state.Region = data;
	},
	openMenu() {
		state.MobileMenu = !state.MobileMenu;
	},
	anketCode(data) {
		state.anketCode = data;
	},
	setMoItems(arr) {
		state.moItems = [];
		arr.forEach((item) => {
			if (item[state.anketTypeName]) {
				state.moItems.push(item);
			}
		});
	},
	adjustSurvey(answer, answerValue, add) {
		for (let i = answer.dependencyQuestions.length - 1; i >= 0; i -= 1) {
			if (answer.dependencyQuestions[i].answerValue === answerValue.toString()) {
				let questionIndex = state.surveyCurrent.findIndex((x) => x.id === answer.dependencyQuestions[i].dependencyQuestionId);

				if (add) {
					let question = state.surveyFull.find((x) => x.id === answer.dependencyQuestions[i].dependencyQuestionId);

					if (questionIndex === -1) {
						state.surveyCurrent.splice(state.questionNumber + 1, 0, question);
					}
				} else if (questionIndex !== -1) {
					state.surveyCurrent.splice(questionIndex, 1);
				}
			}
		}
	},
	resetSurvey() {
		state.surveyCurrent = state.surveyFull.filter((x) => {
			let result = true;

			for (let i = 0; i < state.additionalQuestions.length; i += 1) {
				if (x.id === state.additionalQuestions[i]) {
					result = false;
					break;
				}
			}

			return result;
		});

		state.questionNumber = 0;
		state.moError = null;
	},
	changeStateMo(boll) {
		state.disabledState = boll;
	},
	changeType(param) {
		state.anketTypeName = param;
		state.capchaState = false;
		switch (param) {
			case 'stacionar':
				state.anketType = 'ae06ab6a-8be2-47e7-a692-64f50e98fc59';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях';
				break;
			case 'ambulator':
				state.anketType = 'df1d5f57-53fd-4468-8680-04b56d94669c';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в амбулаторных условиях';
				break;
			case 'healthResort':
				state.anketType = 'f336e95d-3c25-4f5e-9a89-dfe0ee331a50';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях (санаторно-курортные организации)';
				break;
			case 'psychiatric':
				state.anketType = '86f97b7b-7f73-4756-84bd-133515fa6b4c';
				state.title = `Анкета для оценки качества условий оказания услуг медицинскими организациями в стационарных условиях
				(психиатрические больницы, в том числе детские; психоневрологические больницы, в том числе детские)`;
				break;
			case 'donor':
				state.anketType = '7c0b19e6-6d7a-486e-afc8-2ab7acd5299c';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями переливания крови';
				break;
			case 'skoraya':
				state.anketType = '3bf5df65-880b-4cf9-b5b9-726067ffdd53';
				state.title = 'Анкета для оценки качества условий оказания услуг медицинскими организациями скорой медицинской помощи';
				break;
			default:
				state.anketType = '';
		}
	},
	/* eslint-disable */
	setAnketTwo(data, dep) {
		// console.log('data, dep', data, dep);

		state.infoAnket = {
			titleBlock: data.name,
			anketaId: data.anketaId
		};
		state.surveyFull = data.questionBlocks;
		if (dep) {
			state.additionalQuestions = [];
			state.surveyFull.forEach((block) => {
				block.questions.sort((a, b) => a.blockNumber - b.blockNumber);
				block.questions.forEach((question) => {
					question.answers.forEach((answer) => {
						if (answer.dependencyQuestions.length) {
							answer.dependencyQuestions.forEach((dependet) => {
								state.additionalQuestions.push({
									id: dependet.dependencyQuestionId,
									parentId: question.id
								});
							});
						}
					});
				});
			});
		}
		state.additionalQuestions = _.uniq(state.additionalQuestions);
		this.resetAnketTwo();
	},
	resetAnketTwo() {
		state.surveyCurrent = state.surveyFull.map((blocks) => {
			return _.filter(blocks.questions, function (question) {
				question.value = null;
				let delAnswer = true;
				state.additionalQuestions.forEach((item) => {
					if (item.id === question.id) {
						delAnswer = false;
					}
				});
				return delAnswer;
			});
		});

		state.surveyCurrent.forEach((item) => {
			item.map((answer) => {
				answer.value = null;
				return answer;
			});
		});

		state.questionNumber = 0;
	},
	mutationDependencyQuestions(blockIndex, currentId, idArr) {
		let excludeIds = [];

		excludeIds = _.filter(state.additionalQuestions, function (question) {
			let delAnswer = true;

			if (currentId === question.id) {
				delAnswer = false;
			} else if (currentId === question.parentId) {
				if (idArr.find((x) => x === question.id) !== undefined) {
					delAnswer = false;
				}
			} else {
				for (let i = 0; i < state.surveyCurrent.length; i += 1) {
					if (state.surveyCurrent[i].find((x) => x.id === question.id) !== undefined) {
						delAnswer = false;
						break;
					}
				}
			}

			return delAnswer;
		});

		_.uniq(excludeIds);

		state.additionalQuestions.forEach((x) => {
			if (excludeIds.find((y) => y.id === x.id) === undefined && excludeIds.find((y) => y.id === x.parentId) !== undefined) {
				excludeIds.push(x);
			}
		});

		state.surveyCurrent = state.surveyFull.map((block) => {
			return _.filter(block.questions, function (question) {
				let delAnswer = true;
				excludeIds.forEach((item) => {
					if (item.id === question.id) {
						delAnswer = false;
					}
				});

				return delAnswer;
			});
		});

		let block = state.surveyCurrent[blockIndex];
		let lastQuestion = block[block.length - 1];

		if (currentId !== lastQuestion.id) {
			state.questionNumber++;
		}
	}
	/* eslint-enable */
};

export const actions = {
	getRegions(text) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/GetRegions?q=${text}`)
				.then((res) => {
					resolve(res);
					mutations.setRegions(res.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegionById(id) {
		return new Promise((resolve, reject) => {
			if (!id) {
				reject(new Error('wrong id'));
			} else {
				let url = `Region/GetRegionById?regionId=${id}`;
				if (typeof id === 'number' || id.length < 10) {
					url = `Region/GetRegionByOldId?oldId=${id}`;
				}
				this.$http
					.get(url)
					.then((res) => {
						mutations.setRegion(res.data);
						resolve(res);
					})
					.catch((error) => {
						reject(error);
					});
			}
		});
	},
	getMOList(data) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`MO/GetMedicalOrgs?regionId=${data.id}&q=${data.text}`)
				.then((res) => {
					mutations.setMoItems(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMObyId(id) {
		return new Promise((resolve, reject) => {
			if (!id) {
				reject(new Error('wrong id'));
			} else {
				let url = `MO/GetMOById?moId=${id}`;
				if (typeof id === 'number' || id.length < 10) {
					url = `MO/GetMOByOldId?oldId=${id}`;
				}
				this.$http
					.get(url)
					.then((res) => {
						mutations.setMO(true, res.data);
						resolve(res);
					})
					.catch((error) => {
						reject(error);
					});
			}
		});
	},
	getAnketaById() {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Anketa/GetAnketaById?anketaId=${state.anketType}`)
				.then((res) => {
					mutations.setAnket(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getAnketa(anketaId, two) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Anketa/GetAnketaWithBlocks?anketaId=${anketaId}`)
				.then((res) => {
					mutations.setAnketTwo(res.data, two);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	postAnket(anketa) {
		console.log('postAnket(anketa)', anketa);
		let anketType = '';
		switch (anketa.type) {
			case 'ambulator':
				anketType = 'Anketa/PostAmbulatorAnswer';
				break;
			case 'stacionar':
				anketType = 'Anketa/PostStacionarAnswer';
				break;
			case 'healthResort':
				anketType = 'Anketa/PostSanatoriaAnswer';
				break;
			case 'psychiatric':
				anketType = 'Anketa/PostPsychiatricAnswer';
				break;
			case 'donor':
				anketType = 'Anketa/PostDonorAnswer';
				anketa.resulte.RegionId = anketa.region.id;
				break;
			case 'skoraya':
				anketType = 'Anketa/PostSkorayaAnswer';
				anketa.resulte.RegionId = anketa.region.id;
				break;
			default:
				anketType = '';
		}
		return new Promise((resolve, reject) => {
			this.$http
				.post(anketType, anketa.resulte)
				.then((res) => {
					mutations.anketCode(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};

export const methods = {
	showNotification(text, type) {
		this.$toast[type](text, {
			position: 'top-right',
			timeout: 3000,
			closeOnClick: true,
			pauseOnFocusLoss: true,
			pauseOnHover: true,
			draggable: true,
			draggablePercent: 0.6,
			showCloseButtonOnHover: false,
			hideProgressBar: false,
			closeButton: 'button',
			icon: true,
			rtl: false
		});
	}
};
